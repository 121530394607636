import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import index from '../components/index.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  // {
  //   path: '/pregnant',
  //   name: 'pregnant',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/pregnant/pregnant.vue')
  // },
  // {
  //   path: '/points',
  //   name: 'points',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/echart/points.vue')
  // },
  // {
  //   path: '/after',
  //   name: 'after',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/after/after.vue')
  // },
  // {
  //   path: '/baby',
  //   name: 'baby',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../components/baby/baby.vue')
  // }
  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/main.vue')
  },
  {
    path: '/control_panel',
    name: 'control_panel',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/control_panel.vue')
  },
  {
    path: '/FenMain2',
    name: 'FenMain2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/FenMain2.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
