import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import echarts from 'echarts'
// import "echarts/lib/component/legend"
// import Vue from 'vue'
// Vue.prototype.echarts = echarts
import jquery  from 'jquery';
import '@/fonts/font.css'
global.jquery = global.$ = jquery;

createApp(App).use(store).use(router).mount('#app')
