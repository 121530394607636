<template>
<div class="container" :style="'background:url('+url+') center center no-repeat'" style="background-size: contain;background-color: #27869c">
  <div class="pregnant" @click="toMain(1)"></div>
  <div class="after" @click="toMain(2)"></div>
  <div class="baby" @click="toMain(3)"></div>
  <div class="control" @click="toMain(4)"></div>
<!--  <div class="content">-->
<!--&lt;!&ndash;    <div class="pregnant" @click="this.$router.push('/pregnant')">&ndash;&gt;-->
<!--&lt;!&ndash;      <a href="#"></a>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="after" @click="this.$router.push('/after')">&ndash;&gt;-->
<!--&lt;!&ndash;      <a href="#"></a>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <div class="baby" @click="this.$router.push('/baby')">&ndash;&gt;-->
<!--&lt;!&ndash;      <a href="#"></a>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
</div>
</template>

<script>
// import sunbrust from "./echart/sunbrust.vue";
export default {
  name: "index",
  components:{
    // sunbrust
  },
  data () {
    return {
      hover: false,
      url:require('../assets/index/background.png')
      // name:'张雪',
      // xData: ['2020-02', '2020-03', '2020-04', '2020-05'],
      // yData: [30, 132, 80, 134],
    }
  },
  methods:{
    toMain(id){
      let that = this;
      if (id == 1){
        // this.url = require('../assets/index/background1.jpg');
        setTimeout(function (){
          that.$router.push({
            path:"/main",
            query:{
              flag:1
            }
          })
        },100)
      } else if (id == 2){
        // this.url = require('../assets/index/background2.jpg');
        setTimeout(function (){
          that.$router.push({
            path:"/main",
            query:{
              flag:2
            }
          })
        },100)
      } else if (id == 3){
        // this.url = require('../assets/index/background3.jpg');
        setTimeout(function (){
          that.$router.push({
            path:"/main",
            query:{
              flag:3
            }
          })
        },100)
      } else {
        setTimeout(function (){
          that.$router.push({
            path:"/control_panel",
            query:{
              flag:4
            }
          })
        },100)
      }
    }
  },
  mounted () {
    // const {name,xData,yData} = this
    // console.log(this.$refs)
    // this.$refs.chart_line_one.initChart(name,xData,yData)
  }
}
</script>

<style scoped>
.container{
  width: 100%;
  height: 100vh;
  /*padding: 20px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*background: url("../assets/index/background.jpg") center center no-repeat;*/
  background-size: cover;
  overflow: hidden;
  position: relative;
  /*background-color: lightgray;*/
}
.baby{
  width: 260px;
  height: 260px;
  top: 215px;
  left: 160px;
  position: absolute;
  /*background-color: rgba(0,0,0,0.4);*/
  background: url("../assets/index/house1.png") center center no-repeat;
  background-size: contain;
  animation: turn 2s linear infinite;
}
.after{
  width: 280px;
  height: 280px;
  bottom: 45px;
  left: 590px;
  position: absolute;
  /*background-color: rgba(0,0,0,0.4);*/
  background: url("../assets/index/house2.png") center center no-repeat;
  background-size: contain;
  animation: turn 2s linear infinite;
}
.pregnant{
  width: 280px;
  height: 280px;
  top: 110px;
  left: 650px;
  position: absolute;
  /*background-color: rgba(0,0,0,0.4);*/
  background: url("../assets/index/house3.png") center center no-repeat;
  background-size: contain;
  animation: turn 2s linear infinite;
}
.control{
  width: 240px;
  height: 240px;
  bottom: 195px;
  right: 230px;
  position: absolute;
  /*background-color: rgba(0,0,0,0.4);*/
  background: url("../assets/index/house4.png") center center no-repeat;
  background-size: contain;
  animation: turn 2s linear infinite;
}
@keyframes turn {
  0%{
    transform: translateY(0);
  }
  50%{
    transform: translateY(5px);
  }
  100%{
    transform: translateY(0);
  }
}
</style>
